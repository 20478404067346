import React, { useState } from "react";
import { Copyright, FooterContainer, Socket } from "./Footer.style";
import packageJson from "../../../package.json";
import Disclaimer from "../Disclaimer/Disclaimer";
import Link from "../Link/Link";
import Subscribe from "../Subscribe/Subscribe";

const Footer = () => {
  const [shouldShowDisclaimer, setShouldShowDisclaimer] = useState(false);
  const [shouldShowSubscribe, setShouldShowSubscribe] = useState(false);

  const onClickDisclaimer = () => {
    setShouldShowDisclaimer(true);
  };

  const onClickCloseDisclaimer = () => {
    setShouldShowDisclaimer(false);
  };

  const onClickSubscribe = () => {
    setShouldShowSubscribe(true);
  };

  const onClickCloseSubscribe = () => {
    setShouldShowSubscribe(false);
  };

  return (
    <>
      <FooterContainer>
        <Copyright>
          &copy; 2022-2023 Hans M - Graph of Words v{packageJson.version},
          保留所有权利。
        </Copyright>
        <Socket>
          <Link action={onClickDisclaimer}>免责声明</Link>&nbsp;
          <Link action={onClickSubscribe}>更多英语学习内容</Link>
        </Socket>
      </FooterContainer>
      {shouldShowDisclaimer && <Disclaimer close={onClickCloseDisclaimer} />}
      {shouldShowSubscribe && <Subscribe close={onClickCloseSubscribe} />}
    </>
  );
};

export default Footer;

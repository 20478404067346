import styled from 'styled-components';

export const StyledButton = styled.button`
  border: 1px solid #04456f;
  background-color: #04456f;
  color: #fff;
  font-size: 16px;
  padding: 8px 24px;
  border-radius: 5px;
  cursor: pointer;
`;

export const NoColourButton = styled.button`
  border: 1px solid #04456f;
  font-size: 16px;
  padding: 8px 24px;
  border-radius: 5px;
  cursor: pointer;
  color: #04456f;
`;
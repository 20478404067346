import React from "react";
import Button from "../Button/Button";
import Lightbox from "../Lightbox/Lightbox";
import {
  QrCodeImage,
  QrCodeImageWrapper,
  SubscribeButtonWrapper,
  SubscribeText,
} from "./Subscribe.style";
import qrCode from "../../resources/images/qrcode_for_gh_7a6c71bfde0f_860.jpeg";

type SubscribeProps = {
  close: () => void;
};

const ENGLISH_ARTICLES_LINK =
  "https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzIwMTk0NDM5OA==&action=getalbum&album_id=2296433183619465217#wechat_redirect";

const Subscribe = ({ close }: SubscribeProps) => {
  const onClickRead = () => {
    window.open(ENGLISH_ARTICLES_LINK, "_blank");
  };

  return (
    <Lightbox>
      <SubscribeText>
        扫码关注 Hans M 的个人微信公众号“你好老世界”，解锁更多英语学习内容
      </SubscribeText>
      <QrCodeImageWrapper>
        <QrCodeImage src={qrCode} />
      </QrCodeImageWrapper>
      <SubscribeButtonWrapper>
        <Button noColour onClick={close}>
          关闭
        </Button>
        <Button onClick={onClickRead}>不关注了，直接阅读</Button>
      </SubscribeButtonWrapper>
    </Lightbox>
  );
};

export default Subscribe;

import React, { useState } from "react";

import "./App.css";
import Container from "./components/Container/Container.style";
import Edge from "./components/Edge/Edge";
import Node from "./components/Node/Node";
import Graph from "./components/vendor/ReactGraphNetwork/index";
import { searchWords, Word, WordConfig, words } from "./data/words/words";
import transformWordsToGraph, {
  EdgeData,
  NodeData,
} from "./data/words/TransformWords";
import SearchBar from "./components/SearchBar/SearchBar";
import Footer from "./components/Footer/Footer";
import { FullScreenOverlay } from "./components/Overlay/Overlay.style";

function App() {
  const initialGraph: { nodes: NodeData[]; links: EdgeData[] } = {
    nodes: [],
    links: [],
  };
  const [graph, setGraph] = useState(initialGraph);
  const [searchResult, setSearchResult] = useState<Word | null>(null);
  const [isSearchBarFocused, setIsSearchBarFocused] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const query = params?.get('query');

  const search = (searchTerm: string) => {
    const result = searchWords(searchTerm, words);
    if (!result) {
      alert(`找不到 "${searchTerm}"。`);
      return;
    }
    setGraph(transformWordsToGraph([result]));
    setSearchResult(result);
  };

  const filter = (wordConfig: WordConfig) => {
    if (!searchResult) {
      alert("请先搜索词汇。");
      return;
    }
    const filterResults = searchResult[wordConfig];
    if (!filterResults) {
      alert("词汇不存在");
      return;
    }
    setGraph(
      transformWordsToGraph([
        {
          word: searchResult.word,
          description: searchResult.description,
          [wordConfig]: filterResults,
        },
      ])
    );
  };

  const clear = () => {
    setGraph(initialGraph);
    setSearchResult(null);
    if (query) {
      window.location.replace('/');
    }
  };

  const focus = () => {
    setIsSearchBarFocused(true);
  };

  const blur = () => {
    setIsSearchBarFocused(false);
  };

  return (
    <Container>
      <SearchBar
        search={search}
        filter={filter}
        clear={clear}
        focus={focus}
        blur={blur}
        existingQuery={query}
      />
      <Graph
        data={graph}
        id="graph-of-words"
        NodeComponent={Node}
        LineComponent={Edge}
        pullIn={false}
        enableDrag
        zoomDepth={3}
        nodeDistance={800}
        hoverOpacity={0.2}
      />
      {(!searchResult || isSearchBarFocused) && <FullScreenOverlay />}
      <Footer />
    </Container>
  );
}

export default App;

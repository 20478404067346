import styled from 'styled-components';

const SearchBarContainer = styled.div`
  width: 100%;
  max-width: 620px;
  height: 100px;
  z-index: 21;

  * {
    z-index: 21;
  }

  @media (min-width: 768px) {
    position: fixed;
    left: 32px;
    top: 32px;
  }
`;

const SearchBarInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
`;

const SearchInputWrapper = styled.div`
  width: 75%;
`;

const SearchInput = styled.input`
  border-radius: 0;
  border-top-left-radius: 5px;
  border-right: 0;
  padding: 8px 0 8px 32px;
  border: 1px solid #04456f;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const SearchClearButton = styled.span`
  position: absolute;
  top: 16px;
  left: 10px;
  width: 18px;
  color: #888;
  border: 1px solid #888;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
`;

const SearchButton = styled.button`
  border: 1px solid #04456f;
  background-color: #04456f;
  color: #fff;
  font-size: 16px;
  padding: 8px 24px;
  border-radius: 0;
  border-top-right-radius: 5px;
  width: 25%;
  cursor: pointer;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;

  div:first-child {
    border-bottom-left-radius: 5px;
  }

  div:last-child {
    border-bottom-right-radius: 5px;
  }
`;

const FilterItem = styled.div`
  flex: 1;
  padding: 8px;
  text-align: center;
  color: #fff;
  cursor: pointer;
`;

export {
  SearchBarContainer,
  SearchBarInputContainer,
  SearchInputWrapper,
  SearchInput,
  SearchClearButton,
  SearchButton,
  FilterContainer,
  FilterItem,
};
import styled from 'styled-components';

export const FooterContainer = styled.div`
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: 0;
  text-align: center;
  padding: 8px;
  font-size: 12px;
  z-index: 21;
  background-color: #fff;
`;

export const Copyright = styled.div`
  margin-bottom: 8px;
`;

export const Socket = styled.div``;
import styled from 'styled-components';

export const LightboxContainer = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 9;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(23, 23, 23, 0.8);
  z-index: 99;
`;

export const LightboxContent = styled.div`
  width: 70%;
  max-width: 620px;
  background-color: #fff;
  padding: 24px 32px;
`;
enum Variation {
  PAST = '过去式',
  PERFECT = '过去分词',
  CONTINUATION = '进行时',
  NOUN = '名词形式'
}

export enum WordConfig {
  VARIATION = '变体',
  USAGE = '用法',
  SYNONYM = '意义相近',
  ANTONYM = '意义相反',
  SIMILAR = '形似或音似',
  RELATED = '相关',
}

export type Word = {
  word: string;
  description?: string | string[];
  link?: string;
  isRoot?: boolean;
  [WordConfig.USAGE]?: Word[];
  [WordConfig.VARIATION]?: Word[];
  [WordConfig.SYNONYM]?: Word[];
  [WordConfig.ANTONYM]?: Word[];
  [WordConfig.SIMILAR]?: Word[];
  [WordConfig.RELATED]?: Word[];
}

export const words: Word[] = [
  {
    word: 'draw',
    description: [
      '本义：抽，拉',
      '引申义：画，吸引'
    ],
    link: 'https://mp.weixin.qq.com/s/X4qSIeWG-fmCr4EDuGy4ww',
    [WordConfig.VARIATION]: [
      {
        word: 'drew',
        description: Variation.PAST
      },
      {
        word: 'drawn',
        description: Variation.PERFECT
      },
      {
        word: 'drawing',
        description: Variation.CONTINUATION
      }
    ],
    [WordConfig.USAGE]: [
      {
        word: 'draw a deep breath',
        description: '深呼吸'
      },
      {
        word: 'draw tea',
        description: '提取茶叶精华'
      },
      {
        word: 'draw $100 from the bank',
        description: '从银行取100块钱'
      },
      {
        word: 'draw one\'s attention/interest',
        description: '引起某人的注意力/兴趣'
      },
      {
        word: 'draw a conclusion',
        description: '得出结论'
      },
      {
        word: 'round draw',
        description: '平局'
      }
    ],
    [WordConfig.RELATED]: [
      {
        word: 'drawer',
        description: [
          '抽屉',
          '引申自 draw 抽，拉之义，可以抽/拉的东西'
        ]
      },
      {
        word: 'withdraw',
        description: [
          '撤退，撤销，撤出，取出',
          '引申自 draw 抽，拉之义，with 即“相反”，反着抽/拉'
        ],
        [WordConfig.VARIATION]: [
          {
            word: 'withdrew',
            description: Variation.PAST
          },
          {
            word: 'withdrawn',
            description: Variation.PERFECT
          },
          {
            word: 'withdrawing',
            description: Variation.CONTINUATION
          },
          {
            word: 'withdrawal',
            description: Variation.NOUN
          }
        ],
        [WordConfig.USAGE]: [
          {
            word: 'withdraw $100',
            description: '取 100 块钱',
            [WordConfig.ANTONYM]: [
              {
                word: 'deposit $100',
                description: '存 100 块钱'
              }
            ]
          },
          {
            word: 'withdraw/cancel an application',
            description: '撤回/取消申请',
            [WordConfig.ANTONYM]: [
              {
                word: 'submit/lodge an application',
                description: '提交申请'
              }
            ]
          }
        ]
      }
    ],
    [WordConfig.SYNONYM]: [
      {
        word: 'paint',
        description: [
          '应用颜色，绘画',
          '与 draw 的区别在于，draw 主要强调“抽、拉、移动”画笔的动作，如用铅笔在纸上画、粉笔在黑板上画，paint 主要强调”应用颜色“，'
          + '常用于使用液体产生画作，如颜料在画布上作画、墙面刷漆'
        ]
      }
    ],
    [WordConfig.SIMILAR]: [
      {
        word: 'draft',
        description: '抽，拉，挑选，草稿',
        [WordConfig.RELATED]: [
          {
            word: 'draftsperson',
            description: '草图制图师（职业）'
          }
        ]
      },
      {
        word: 'drown',
        description: '淹没，浸入在水或其他液体中'
      },
      {
        word: 'drone',
        description: '无人机，使用遥控的无人值守飞机或船只'
      },
      {
        word: 'John',
        description: '英语中常见的人名'
      }
    ]
  },
  {
    word: 'use',
    description: '用',
    link: 'https://mp.weixin.qq.com/s?__biz=MzIwMTk0NDM5OA==&mid=2247483787&idx=1&sn=557f1b863abb23b1eba0b4b65c84552b&chksm=96e77daaa190f4bc71908309863b4d36750a4deab1d5e66598deed4bd25d039b06cfd98f2699&token=138979752&lang=zh_CN#rd',
    [WordConfig.VARIATION]: [
      {
        word: 'using',
        description: '正在用。现在时或动名词'
      },
      {
        word: 'used',
        description: '过去用过，已经用了。过去式和过去分词'
      },
      {
        word: 'usage',
        description: '用处。名词'
      },
      {
        word: 'user',
        description: '使用者，用户。名词'
      },
      {
        word: 'useful',
        description: '有用的。形容词',
        [WordConfig.ANTONYM]: [
          {
            word: 'useless',
            description: '无用的。形容词'
          }
        ]
      },
      {
        word: 'useless',
        description: '无用的。形容词',
        [WordConfig.ANTONYM]: [
          {
            word: 'useful',
            description: '有用的。形容词',
          }
        ]
      }
    ],
    [WordConfig.USAGE]: [
      {
        word: 'I will use that public phone to call him',
        description: '我将用那个公共电话打给他'
      },
      {
        word: 'used car',
        description: '二手车，已经用过的车',
        [WordConfig.SYNONYM]: [
          {
            word: 'second-handed car',
            description: '二手车'
          }
        ]
      },
      {
        word: 'Let\'s make use of those spare CDs',
        description: '让我们利用这些闲置的光碟'
      },
      {
        word: 'We will put your investment to good use',
        description: '我们将有效利用你的投资'
      },
      {
        word: 'used to',
        description: [
          '被用于',
          '习惯于'
        ],
        [WordConfig.USAGE]: [
          {
            word: 'The bin is used to carry waste',
            description: [
              '这个桶是用来装垃圾的',
              '取 use 的使用之意'
            ]
          },
          {
            word: 'I used to play basketball a lot',
            description: [
              '我过去经常打篮球',
              '强调过去经常',
              '取 use 的古意，习惯于'
            ]
          },
          {
            word: 'I am used to working from home',
            description: [
              '我习惯了在家工作',
              '强调现在',
              '取 use 的古意，习惯于'
            ]
          }
        ],
        [WordConfig.SYNONYM]: [
          {
            word: 'in the habit of',
            description: '习惯于'
          },
          {
            word: 'accustomed to',
            description: '习惯于'
          }
        ]
      }
    ],
    [WordConfig.SYNONYM]: [
      {
        word: 'utilise',
        description: [
          '利用，to make use of',
          '强调用什么东西做什么事，但这个东西本身不适用于干这事的',
          '一般可以与 use 混用',
          'utilize'
        ],
        [WordConfig.VARIATION]: [
          {
            word: 'utilisation',
            description: '利用率。名词'
          },
          {
            word: 'utility',
            description: '效用，用具。名词'
          }
        ]
      },
      {
        word: 'leverage',
        description: [
          '利用（杠杆）'
        ],
        [WordConfig.VARIATION]: [
          {
            word: 'lever',
            description: '杠杆。名词'
          }
        ]
      },
      {
        word: 'take advantage of',
        description: [
          '利用',
          '也可以表示：利用别人的把柄为自己谋取利益，不公平地对待别人来为自己牟利'
        ]
      }
    ]
  },
  {
    word: 'strange',
    description: [
      '本义：奇怪的，与正常的/期待的不相同的',
      '引申义：陌生的，不认识的，不舒服的'
    ],
    link: 'https://mp.weixin.qq.com/s?__biz=MzIwMTk0NDM5OA==&mid=2247483796&idx=1&sn=7a190365beff4bff9d00bd5091c33f26&chksm=96e77db5a190f4a386b2d0a59af4460bd9ff2e51ed021433d4a72794e55c3653b8d48257bdad#rd',
    [WordConfig.VARIATION]: [
      {
        word: 'stranger',
        description: '陌生人。名词',
        [WordConfig.USAGE]: [
          {
            word: 'Alice is a stranger to Bob',
            description: '鲍勃不认识爱丽丝',
          }
        ]
      }
    ],
    [WordConfig.USAGE]: [
      {
        word: 'Alice is strange to Bob',
        description: '爱丽丝不认识鲍勃',
        [WordConfig.SYNONYM]: [
          {
            word: 'Alice is unfamiliar with Bob',
            description: '爱丽丝不认识鲍勃'
          }
        ]
      },
      {
        word: 'I feel a bit strange this morning',
        description: '我今早感觉不太舒服',
        [WordConfig.SYNONYM]: [
          {
            word: 'I feel a bit odd this morning',
            description: '我今早感觉不太舒服',
          },
          {
            word: 'I feel a bit under the weather today',
            description: '我今天不太舒服',
          },
          {
            word: 'I\'m not at my 100% today',
            description: '我今天不太舒服，状态不好，没在“满格”的状态',
          },
          {
            word: 'I\'m not feeling great today',
            description: '我今天不太舒服',
          },
          {
            word: 'I\'m sick today',
            description: '我今天病了',
          }
        ]
      }
    ],
    [WordConfig.SYNONYM]: [
      {
        word: 'odd',
        description: [
          '本义：奇怪的，与正常的/期待的不相同的',
          '引申义：单个的，奇数的，不舒服的'
        ],
        [WordConfig.USAGE]: [
          {
            word: 'I feel a bit odd this morning',
            description: '我今早感觉不太舒服',
          },
          {
            word: 'on the odd occasion',
            description: '偶尔，少数时候'
          },
          {
            word: 'an odd glove',
            description: '一只单个的手套'
          },
          {
            word: 'the odd bunch',
            description: '有的超市能见到，一般是那些卖相不太好，奇形怪状但品质没问题的蔬菜'
          }
        ]
      },
      {
        word: 'weird',
        description: '奇怪的，因神秘而感到奇怪的，因超自然、无法理解而感到奇怪的',
        [WordConfig.VARIATION]: [
          {
            word: 'weirdo',
            description: '怪胎，奇怪的人'
          }
        ]
      },
      {
        word: 'bizarre',
        description: '极其（strikingly）奇怪的',
      },
      {
        word: 'peculiar',
        description: '某一个人/一个群体/一个事物与众不同的特质，奇怪的'
      },
      {
        word: 'quirky',
        description: '与众不同的，尤其是让人感到有趣或吸引人的，奇怪的'
      },
    ],
  }
];

export const searchWords = (searchTerm: string, wordsLibrary: Word[]): Word | null => {
  const wordFound = wordsLibrary.find((word) => word.word.toLowerCase() === searchTerm.toLowerCase());
  if (wordFound) {
    return wordFound;
  }
  for (const word of wordsLibrary) {
    for (const config of Object.values(WordConfig)) {
      if (!word[config]) {
        continue;
      }
      const childWordFound = searchWords(searchTerm, word[config]!);
      if (childWordFound) {
        return childWordFound;
      }
    }
  }
  return null;
};

import React, { useState, ChangeEvent, KeyboardEvent, createRef, useEffect } from "react";
import { WordConfig } from "../../data/words/words";
import { edgeColours } from "../../theme/colour";
import {
  FilterContainer,
  FilterItem,
  SearchBarContainer,
  SearchBarInputContainer,
  SearchButton,
  SearchClearButton,
  SearchInput,
  SearchInputWrapper,
} from "./SearchBar.style";

type SearchBarProps = {
  search: (searchTerm: string) => void;
  filter: (relation: WordConfig) => void;
  clear: () => void;
  focus: () => void;
  blur: () => void;
  existingQuery: string | null;
};

const SearchBar = ({ search, filter, clear, focus, blur, existingQuery }: SearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [shouldShowClearButton, setShouldShowClearButton] = useState(false);
  const [inputRef] = useState(createRef<HTMLInputElement>());

  const onSearchContentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setShouldShowClearButton(event.target.value.length > 0);
  };

  const onClickSearch = (isEnterKey?: boolean, onEnterKey?: () => void) => {
    if (!searchTerm || searchTerm.length < 2) {
      alert("搜索词太短，请输入至少 2 个字母。");
      return;
    }
    if (isEnterKey && onEnterKey) {
      onEnterKey();
    }
    console.log("Searching", searchTerm);
    search(searchTerm);
  };

  const onClickClear = () => {
    setSearchTerm("");
    setShouldShowClearButton(false);
    clear();
  };

  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      onClickSearch(true, () => inputRef.current?.blur());
    }
  };

  useEffect(() => {
    if (existingQuery) {
      setSearchTerm(existingQuery);
      setShouldShowClearButton(true);
      search(existingQuery);
    }
  }, [shouldShowClearButton]);

  return (
    <SearchBarContainer>
      <SearchBarInputContainer>
        <SearchInputWrapper>
          <SearchInput
            type="text"
            ref={inputRef}
            placeholder="请输入要查找的词汇"
            value={searchTerm}
            onChange={onSearchContentChange}
            onKeyUp={onKeyUp}
            onFocus={focus}
            onBlur={blur}
          />
          {shouldShowClearButton && (
            <SearchClearButton onClick={onClickClear}>
              &#x2715;
            </SearchClearButton>
          )}
        </SearchInputWrapper>
        <SearchButton onClick={() => onClickSearch()}>搜索</SearchButton>
      </SearchBarInputContainer>
      <FilterContainer>
        {Object.values(WordConfig)
          .map((key) => ({
            relation: key,
            colour: edgeColours[key],
          }))
          .map((item) => (
            <FilterItem
              key={item.relation}
              onClick={() => filter(item.relation)}
              style={{ backgroundColor: item.colour }}
            >
              {item.relation}
            </FilterItem>
          ))}
      </FilterContainer>
    </SearchBarContainer>
  );
};

export default SearchBar;

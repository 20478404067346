import React, { ReactNode } from "react";
import { StyledLink } from "./Link.style";

type LinkHrefProp = {
  href: string;
  shouldOpenInNewTab: boolean;
};

type LinkProp = {
  href?: LinkHrefProp;
  action?: () => void;
  children: ReactNode;
};

const Link = ({ href, action, children }: LinkProp) => {
  if (href) {
    return (
      <StyledLink
        href={href.href}
        target={href.shouldOpenInNewTab ? "_blank" : ""}
      >
        {children}
      </StyledLink>
    );
  }
  return <StyledLink onClick={action}>{children}</StyledLink>;
};

export default Link;

import styled from 'styled-components';

export const DisclaimerTitle = styled.h2`
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 32px;
  font-size: 32px;
`;

export const DisclaimerText = styled.p`
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
`;

export const DisclaimerButtonWrapper = styled.div`
  text-align: center;
`;
import React, { ReactNode } from "react";
import { NoColourButton, StyledButton } from "./Button.style";

type ButtonProps = {
  onClick: () => void;
  children: ReactNode;
  noColour?: boolean;
};

const Button = ({ onClick, children, noColour }: ButtonProps) => {
  if (noColour) {
    return <NoColourButton onClick={onClick}>{children}</NoColourButton>;
  }
  return <StyledButton onClick={onClick}>{children}</StyledButton>;
};

export default Button;

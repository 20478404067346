import styled from 'styled-components';

const NodeCircle = styled.circle``;

const NodeTitle = styled.g`
  font-size: 16px;
`;

const NodeTitleText = styled.text``;

const NodeDescription = styled.g`
  font-size: 12px;
`;

const NodeDescriptionText = styled.text``;

export {
  NodeCircle,
  NodeTitle,
  NodeTitleText,
  NodeDescription,
  NodeDescriptionText,
};
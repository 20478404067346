import React, { useState } from "react";
import { NodeData } from "../../data/words/TransformWords";
import {
  NodeCircle,
  NodeDescription,
  NodeDescriptionText,
  NodeTitle,
  NodeTitleText,
} from "./Node.style";

const NODE_RADIUS = 15;
const NODE_TITLE = {
  x: NODE_RADIUS + 6,
  y: NODE_RADIUS / 2,
};

type NodeProps = {
  node: NodeData;
};

const Node = ({ node }: NodeProps) => {
  const [shouldDisplayDescription, setShouldDisplayDescription] =
    useState(false);

  const fillColour = node.isCentre ? "#6108f7" : "#00fff3";

  const onHoverNode = () => {
    console.log("over", node.id, node.description);
    setShouldDisplayDescription(true);
  };

  const onLeaveNode = () => {
    setShouldDisplayDescription(false);
  };

  const onClickNode = () => {
    if (node.linkOnClick) {
      window.open(node.linkOnClick, "_blank");
    }
  };

  let nodeDescription: string[] = [];
  if (typeof node.description === "string") {
    nodeDescription = [node.description];
  } else if (Array.isArray(node.description)) {
    nodeDescription = node.description;
  }

  return (
    <>
      <NodeCircle
        style={{ cursor: node.linkOnClick ? "pointer" : "initial" }}
        onMouseOver={onHoverNode}
        onMouseLeave={onLeaveNode}
        onClick={onClickNode}
        r={NODE_RADIUS}
        stroke="#aaa"
        fill={fillColour}
      />
      <NodeTitle>
        <NodeTitleText {...NODE_TITLE}>
          {node.id}
          {node.linkOnClick && " (点击查看讲解)"}
        </NodeTitleText>
      </NodeTitle>
      {shouldDisplayDescription && nodeDescription && (
        <NodeDescription>
          {nodeDescription.map((description, index) => (
            <NodeDescriptionText
              x={NODE_TITLE.x}
              y={NODE_TITLE.y + 16 * (index + 1)}
              key={description}
            >
              {description}
            </NodeDescriptionText>
          ))}
        </NodeDescription>
      )}
    </>
  );
};

export default Node;

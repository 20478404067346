import { EdgeData } from "../../data/words/TransformWords";
import { edgeColours } from "../../theme/colour";
import { EdgeLine } from "./Edge.style";

type EdgePropType = {
  link: EdgeData;
};

const Edge = (props: EdgePropType) => (
  <EdgeLine
    stroke={edgeColours[props.link.relation]}
    strokeWidth={1}
    {...props}
  />
);

export default Edge;

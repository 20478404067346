import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  height: calc(100vh - 110px);

  @media (min-width: 768px) {
    height: 100vh;
  }
`;

export default Container;
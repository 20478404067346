import { Word, WordConfig } from "./words";

export type NodeData = {
  id: string;
  description?: string | string[];
  relationWithParent?: WordConfig;
  isCentre?: boolean;
  linkOnClick?: string;
}

export type EdgeData = {
  source: string;
  target: string;
  relation: WordConfig | '未知';
};

type GraphData = {
  nodes: NodeData[];
  links: EdgeData[];
}

const toEdge = (wordNode: NodeData, childWordNode: NodeData): EdgeData => ({
  source: wordNode.id,
  target: childWordNode.id,
  relation: childWordNode.relationWithParent ?? '未知'
});

const toChildWordNodes = (word: Word): NodeData[] =>
  Object.values(WordConfig).flatMap((config) =>
    word[config]?.map((childWord) => ({
      id: childWord.word,
      relationWithParent: config,
      description: word.description
    })) ?? []
  );

const transformWordToGraph = (word: Word): GraphData => {
  const wordNode: NodeData = {
    id: word.word,
    description: word.description,
    linkOnClick: word.link,
    isCentre: !!word.isRoot
  };
  const childWordNodes = toChildWordNodes(word);
  const links = childWordNodes.map((node) => toEdge(wordNode, node));
  const childGraphs: GraphData[] = Object.values(WordConfig)
    .filter(config => !!word[config])
    .map(config => word[config])
    .flatMap(childWords => childWords?.map(transformWordToGraph) ?? [])
    .filter(graph => !!graph);

  return {
    nodes: [wordNode, ...childGraphs.flatMap(graph => graph.nodes)],
    links: [...links, ...childGraphs.flatMap(graph => graph.links)]
  };
}

const transformWordsToGraph = (words: Word[]): GraphData => {
  const graph = words
    .map(word => Object.assign(word, { isRoot: true }))
    .map(transformWordToGraph)
    .reduce((cur, inc) => ({
      nodes: [...cur.nodes, ...inc.nodes],
      links: [...cur.links, ...inc.links]
    }), { nodes: [], links: [] });
  return graph;
}


export default transformWordsToGraph;
import React from "react";
import Button from "../Button/Button";
import Lightbox from "../Lightbox/Lightbox";
import {
  DisclaimerButtonWrapper,
  DisclaimerText,
  DisclaimerTitle,
} from "./Disclaimer.style";

type DisclaimerProps = {
  close: () => void;
};

const Disclaimer = ({ close }: DisclaimerProps) => {
  return (
    <Lightbox>
      <DisclaimerTitle>免责声明</DisclaimerTitle>
      <DisclaimerText>
        感谢大家使用 Graph of Words (https://www.graphofwords.net)。本网站
        为在线免费英语学习辅助网站。该网站内容由网站作者 (Hans M)
        提供，仅为网站作者基于对英语词汇的兴趣，查阅资料后产生的自行理解。作者对本网站内容不保证任何学术权威性，不保证正确性
        (但接受并感激勘误)。若需用于商业或学术用途，请自行承担一切后果。
      </DisclaimerText>
      <DisclaimerText>
        本网站最终解释权归网站作者 (Hans M) 所有。
      </DisclaimerText>
      <DisclaimerButtonWrapper>
        <Button onClick={close}>知晓并同意</Button>
      </DisclaimerButtonWrapper>
    </Lightbox>
  );
};

export default Disclaimer;

import React, { ReactNode } from "react";
import { LightboxContainer, LightboxContent } from "./Lightbox.style";

type LightboxProps = {
  children: ReactNode;
};

const Lightbox = ({ children }: LightboxProps) => {
  return (
    <LightboxContainer>
      <LightboxContent>{children}</LightboxContent>
    </LightboxContainer>
  );
};

export default Lightbox;

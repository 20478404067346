import styled from 'styled-components';

export const FullScreenOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(23, 23, 23, 0.8);
`;
import styled from 'styled-components';

export const SubscribeButtonWrapper = styled.div`
  text-align: center;

  button:not(:last-child) {
    margin-right: 8px;
  }
`;

export const QrCodeImageWrapper = styled.div`
  text-align: center;
`;

export const QrCodeImage = styled.img`
  width: 100%;
  max-width: 300px;
`;

export const SubscribeText = styled.p`
  padding: 0;
  margin: 0;
  text-align: center;
`;
import { WordConfig } from "../data/words/words";

export const randomColour = () => `#${Math.floor(Math.random() * 11184810).toString(16).padStart(6, '0')}`;

export const edgeColours: { [key: string]: string } = {
  [WordConfig.VARIATION]: randomColour(),
  [WordConfig.USAGE]: randomColour(),
  [WordConfig.SYNONYM]: randomColour(),
  [WordConfig.ANTONYM]: randomColour(),
  [WordConfig.SIMILAR]: randomColour(),
  [WordConfig.RELATED]: randomColour(),
};